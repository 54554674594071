@import "../../styles/variables";
@import "../../styles/dataset-report";

.cor-table {
  color: $black;

  .cor-table-container {
    padding: 0;

    .ReactTable {
      overflow-x: overlay;
      border: 1px solid $border-color;

      .rt-thead {
        border-bottom: 1px solid $border-color;
        box-shadow: none;

        .rt-th {
          border-right: 1px solid $border-color;
          text-align: start;
          font-size: 14px;
          padding: 0;;

          .cor_hdr_style {
            padding: 5px 0.6rem;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
      .rt-tbody {
        overflow-y: hidden;

        .rt-tr-group {
          min-height: 15px;
          border-bottom: solid 1px $border-color;

          .rt-tr {
            margin-bottom: -1px;
          }

          .rt-td {
            min-width: 160px;
            border-right: 1px solid $border-color;

            &:last-child {
              border-bottom: none !important;
            }
          }

          &:last-child {
            border-bottom: none !important;
          }

          .td-style {
            display: flex;
            justify-content: center;
            align-items: center;

            .cor-first-axes {
              display: flex;
            }

            .cor-first-column {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left-col-styles {
                overflow: hidden;
                text-align: start;
                padding: 5px 0.6rem;
                flex: 1;
                font-size: 14px;
              }
            }

            .diagonal-line {
              width: 100%;
              height: 100%;
            }

            .diagonal-line {
              height: 120px;
            }
          }
        }
      }
    }
  }
}
