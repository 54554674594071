@import "../../styles/variables";
@import "../../styles/dataset-report";

$rs_font-size: 12px;

@mixin title_style() {
  user-select: none;
  font-size: $rs_font-size;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 18px;
}

@mixin drop_and_input() {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  background: #f5f8fa;
  color: rgb(92, 92, 92);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rp-categories {
  color: $black;

  .selects_name_header {
    @include _title_names();
  }

  .rp-cat {
    margin-top: 10px;
    padding: 5px 0;
    width: 100%;

    .cat-title {
      @include title_style();
    }

    .cat-main {
      margin-top: 4px;

      .rp-form-style {
        display: flex;
        flex-direction: column;

        .rp-subcat {
          @include title_style();
          font-size: $rs_font-size;
          font-weight: 700;
          margin-top: 6px;
          text-transform: none;
          color: $black;
        }

        .input-item {
          display: flex;
          height: 28px;

          .checkbox-container {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include _fonts_dis_keeps_select();
            font-weight: $font-weight;

            ._selects-checks {
              @include _checkbox();
            }
          }

          .item-color {
            height: 100%;
            width: 4px;
            background-color: blue;
          }

          .item-label {
            width: 100%;
            border-top: 1px solid;
            border-right: 1px solid;
            border-color: silver;
            background-color: #f5f8fa;
            padding: 0 8px;
            @include drop_and_input();
            display: block;

            &:hover {
              background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0)) 0 no-repeat,
                50% no-repeat #ebf1f5;
            }
          }
        }

        .correlation-rp,
        .modelling-rp {
          &:last-child {
            .item-color {
              border-bottom-left-radius: 3px;
            }
          }

          &:last-child {
            .item-label {
              border-bottom-right-radius: 3px;
              border-bottom: 1px solid;
              border-color: silver;
            }
          }
        }

        .correlation-rp,
        .modelling-rp {
          &:first-child {
            .item-label {
              border-top-right-radius: 3px;
            }

            .item-color {
              border-top-left-radius: 3px;
            }
          }
        }

        .label-style {
          font-size: 14px;
        }
      }

      .rp-dropdown {
        padding: 0 10px;
        justify-content: space-between;
        height: $input-height;
        border-radius: 4px;
        background: $white !important;
        background-clip: padding-box;
        border: $border-width solid $border-color;
        color: $black;
        @include drop_and_input();

        &:focus-within {
          border-color: $blue_royal;
        }

        img {
          margin-top: -2px;
          height: 11px;
          width: 8px;
          transform: rotate(90deg);
        }

        .dropdown-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: $rs_font-size;
          color: $black;
          font-weight: 700;
        }
      }

      .dropdown-content {
        padding: 0;
        border-radius: 4px;
        margin-top: 0px;
        width: 210px;
        max-height: 180px;
        overflow-y: overlay;
        box-shadow: none;
        border: $border-width solid $border-color;

        .li-drop-style {
          padding: 0 10px;
          height: $input-height;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: $rs_font-size;
          color: $black;
          font-weight: 700;

          .drop-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:hover {
            background-color: #deebff;
          }
        }
      }
    }
  }
}
