@import "../../styles/variables";

.mod-table {
  .mod-table-container {
    padding: 0;

    .header {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .table {
      height: auto;
      padding: 0;

      .ReactTable {
        border: $border-width solid $border-color;

        .rt-table {
          overflow-x: auto;
        }
      }

      .rt-thead.-header {
        box-shadow: none;
        border-bottom: $border-width solid $border-color;
      }

      .rt-thead,
      .rt-tbody {
        height: 5rem;
        
        .rt-tr-group {
          border-bottom: none;
        }

        .rt-th,
        .rt-td {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .rt-td {
          font-size: 14px !important;
          font-weight: $font-weight !important;
        }
      }
    }
  }
}
