@import "../styles/variables";

$drp_padding: 0.6rem; // inner padding in blocks dataset
$drp_l_font-size: 12px; // font size for litle text
$drp_b_font-size: 1rem; // font size for litle text
$drp_table_bgr-color: #f9faff; // background statisticks
$drp_report_button-width: 8.5rem; // report button width

// light text in dataset statistics(value)
@mixin _light_text_stat_rp() {
  font-size: 12px;
  font-weight: 400;
  color: #8d93a4;
}

@mixin _checkbox() {
  border-radius: 4px;
  margin-right: 4px;
}

// fonts for down block after chart and report selects
@mixin _fonts_dis_keeps_select() {
  font-size: 12px;
  font-weight: 400;
}

// fonts for selects headers, card names, rp names
@mixin _title_names() {
  font-size: $drp_b_font-size;
  font-weight: $font-weight;
  color: $black;
}

@mixin _table_row() {
  padding: 0.3rem $drp_padding;
  font-family: monospace;
  font-size: 0.815rem;
  font-weight: 400;
  overflow: hidden;
  color: #1d2642;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: end;
  max-width: 100%;
}
