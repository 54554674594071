@import "../../styles/variables";

.source {
  height: 100%;

  .source_content {
    padding: $padding;
    padding-bottom: 0;
    overflow: hidden;

    .main_source {
      padding-right: 25px;

      .drop_chart_container {
        width: 330px;
        height: 100%;
        background-color: yellow;
        border: 1px solid red;
      }

      .tb_column {
        height: 100%;
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  .custom_height_style {
    height: 100%;
  }

  .custom_width_style {
    width: 100%;
  }

  .rp_source {
    margin-bottom: $padding;
    width: 200px;
    display: flex;
    flex-direction: column;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: 0.5rem;

    ._query-header {
      margin-bottom: 0;
      font-weight: $font-weight;
      font-size: $font-size;
      text-align: start;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .query_container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      overflow-y: auto;

      ._create-project {
        @include _button($button-width, $green_paris);
      }
    }
  }
}
