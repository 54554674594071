@import "../../styles/variables";

// CONSTANTS

$dsr_font-size: 15px; // font size for bold text in datasource card
$dsr_bold-font-weight: bold; // font weight for bold text in datasource card
$dsr_light-font-weight: 400; // font weight for light text in datasource card
$dsr_light-color: $light_label_color; // color light text in datasource card

.datasources {
  height: 100%;

  ._datasources-list {
    padding: $padding!important;
    overflow: auto;
  }

  .custom_card_style {
    min-width: 300px;

    ._datasources-text-bold {
      font-size: $dsr_font-size;
      font-weight: $dsr_bold-font-weight;
      color: $black;
    }

    ._datasources-text-light {
      font-size: 14px;
      font-weight: $dsr_light-font-weight;
      color: $dsr_light-color;
    }

    ._dsr-edit-button {
      @include _button($button-width, $blue_light_steel);

      &:hover {
        background: $white;
        border-color: $blue_light_steel;
        color: $black;
      }
    }

    ._dsr-create-button {
      @include _button($button-width, $green_paris);

      &:hover {
        background: $white;
        border-color: $green_paris;
        color: $black;
      }
    }
  }

  .plus_new_datasource {
    display: flex;
    align-items: center;
    justify-content: center;

    .plus_container {
      display: flex;
      width: 8rem;
      height: 8rem;
      align-items: center;
      justify-content: center;
      background-color: #fed9a61a;
      border: 1px solid #00000024;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #fed9a62b;
      }
    }
  }
}

#edit-datasource {
  .error {
    .css-bg1rzq-control,
    .css-1szy77t-control {
      border: $border-width solid $red;
    }
  }
}
