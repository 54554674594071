@import '../../styles/variables';

// CONSTANTS

$prl_font-size: 0.7rem;
$prl_font-weight: $font-weight;
// $prl_light_label_color: $light_label_color;
$prl_light_label_color: #8e8e93;
$prl_table_border_width: $border-width; // table border width
$prl_table_border_color: $border-color; // table border color
$prl_table_hover_border_color: $blue_royal; // table hover border color
$prl_table_hover_border_width: 2px; // table hover border width
$prl_icons_color: rgba($border-color, 0.3);

$prl_border-radius: $border-radius; // for popover, status div

// ICONS

$prl_icon_1: '../../images/icons/delete_icon.png';
$prl_icon_2: '../../images/icons/edit_icon.png';

// HOVER ICONS

$prl_icon_1_h: '../../images/icons/delete_icon_hover.png';
$prl_icon_2_h: '../../images/icons/edit_icon_hover.png';

@mixin nowrap_line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preload_page {
  height: 100%;
}

.list-datasets-templates {
  height: 100%;
  font-size: $prl_font-size;
  padding: $padding;

  .list-datasets-templates-container {
    padding: 15px;
    height: 100%;

    .table {
      // These styles are suggested for the table fill all available space in its containing element
      display: block;
      // These styles are required for a horizontaly scrollable table overflow
      overflow: hidden;
      overflow-x: overlay;
      padding-left: 0;
      margin-bottom: 0;
      border-spacing: 0;

      .thead {
        // These styles are required for a scrollable body to align with the header properly
        overflow-y: auto;
        overflow-x: hidden;

        .tr {
          border-bottom: none;
          margin: 0 1px;
          border-left: $prl_table_hover_border_width solid transparent;

          .th {
            .header_style {
              font-size: $prl_font-size;
              font-weight: $prl_font-weight;
              text-transform: uppercase;
            }

            .table-sort-icons {
              margin-left: 5px;

              .sort-icon,
              .desc,
              .asc {
                width: 10px;
                height: 10px;
                color: $prl_table_hover_border_color;
                font-size: 12px;
                line-height: 1.66666667;
              }

              .sort-icon::after {
                content: "\f0dc";
                font-style: normal;
              }

              .desc::after {
                content: "\f0dd";
                font-style: normal;
              }

              .asc::after {
                content: "\f0de";
                font-style: normal;
              }
            }

            .resizer {
              right: -0.5rem;
              width: 1rem;
              height: 100%;
              position: absolute;
              top: 0;
              z-index: 1;
              touch-action: none;
              cursor: ew-resize;

              &.isResizing {
                cursor: ew-resize;
                width: 100vw;
                right: 0;
                left: 0;
                top: 0;
              }
            }

            .resize-divider {
              background: $prl_table_border_color;
              width: 1px;
              height: 1.5rem;
              touch-action: none;
              position: absolute;
              right: 0;
            }
          }
        }
      }

      .tbody {
        // These styles are required for a scrollable table body
        // overflow: hidden;
        height: calc(100% - 36px); // 100% - header height
        border: $prl_table_border_width solid $prl_table_border_color;

        .tr {
          cursor: pointer;
          border-left: $prl_table_hover_border_width solid transparent;

          &:hover {
            background: #f9f8f8;
            border-left: $prl_table_hover_border_width solid $prl_table_hover_border_color;
          }
        }
      }

      .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        .noData_style {
          font-size: 16px;
          font-weight: 700;
          color: #8e8e93;
        }
      }

      .tr {
        border-bottom: $prl_table_border_width solid $prl_table_border_color;
        padding-right: 0 !important;

        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }

      .th {
        color: $prl_light_label_color;

        .type_arrow {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 5rem;
          background-color: rgba(224, 228, 237, 0.3);
          justify-content: center;
          border-radius: 10px;
          padding: 0.1rem 0;

          img {
            height: 7px;
            width: 11px;
            margin-left: 4px;
          }
        }
      }

      .th,
      .td {
        margin: 0;
        padding: 0.5rem;
        border-right: none;
        display: flex;
        align-items: center;

        // In this example we use an absolutely position resizer, so this is required.
        position: relative;
      }

      .th {
        &:last-of-type {
          .resizer {
            // note that the 15 is the scroll width and if also referenced in the getHeaderGroupProps for the header row below
            // todo: resolve this value dynamicaly from element.scrollWidth to account for OS/Browser differences
            right: -15px;
          }
        }
      }
    }

    .header_style {
      font-size: $prl_font-size;
      font-weight: $prl_font-weight;
    }

    .name_column,
    .description_column {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name_column {
      font-weight: $prl_font-weight;
    }

    .description_column {
      color: $prl_light_label_color;
    }

    .status_column {
      display: flex;
      align-items: center;
      justify-content: center;

      .status_bgr {
        border-radius: $prl_border-radius;
        width: 5rem;
        text-align: center;

        .status_text {
          padding: 0.1rem 0.5rem;
        }
      }

      .status_bgr.dataset {
        background-color: $prl_dataset_bgr;

        .status_text {
          color: $prl_dataset_text;
        }
      }

      .status_bgr.curation {
        background-color: $prl_curation_bgr;

        .status_text {
          color: $prl_curation_text;
        }
      }
    }

    .uk-container {
      max-width: 100%;
      padding: 0;

      .link-preload-page {
        text-decoration: none;
        cursor: default;

        .uk-card {
          padding: 20px;
          border-radius: $border-radius;

          .header-card {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .list-item {
              font-size: 16px;
              font-weight: bold;
              color: $black;
              margin-bottom: 0;
              padding-right: 7px;
              @include nowrap_line();
            }

            .edit-card-header,
            .delete-card {
              color: #80808070;
              cursor: pointer;
            }

            .edit-card-header {
              margin-left: 5px;

              &:hover {
                color: #808080c4;
              }
            }

            .delete-card {
              margin-left: 5px;

              &:hover {
                color: red;
              }
            }
          }

          .desc-style {
            font-size: 14px;
            font-weight: 400;
            color: $light_label_color;
            @include nowrap_line();
          }
        }
      }
    }
  }
}

.list-datasets-templates,
#share-project {
  .row_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $prl_icons_color;
    margin: 0 5px;
    cursor: pointer;

    ._delete-icon,
    ._edit-icon {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 50%;
    }

    ._delete-icon {
      background-image: url($prl_icon_1);
    }

    ._edit-icon {
      background-image: url($prl_icon_2);
    }

    &:hover {
      ._delete-icon {
        background-image: url($prl_icon_1_h);
      }

      ._edit-icon {
        background-image: url($prl_icon_2_h);
      }
    }
  }
}

// POPOVER STYLES

.rs-popover {
  border: 1px solid $prl_table_border_color;
  filter: none;
  padding: 0 12px;
  border-radius: $prl_border-radius;
  color: $prl_light_label_color;
  font-size: $prl_font-size;
  font-weight: $prl_font-weight;
  text-transform: uppercase;
  box-shadow: 0 3px 10px rgba($prl_light_label_color, 0.3);
}

.rs-popover[class*='placement-bottom'] > .arrow:after {
  margin-left: 0;
}

.rs-popover.placement-bottom > .arrow,
.rs-popover.placement-top > .arrow {
  left: 0;
}

.rs-popover[class*='placement-bottom'] > .arrow {
  margin-left: -5px;
}

.rs-popover > .arrow {
  font-size: 14px;
  position: absolute;
  width: 100%;
  left: 0%;
}

.rs-popover > .arrow::before,
.rs-popover > .arrow::after {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 50%;
}

.rs-popover > .arrow::before {
  border-bottom: 5px solid $prl_table_border_color;
  top: 0;
}

.rs-popover > .arrow::after {
  border-bottom: 5px solid white;
  top: -5px;
}

// TABLE STYLES

.rs-table-cell-content {
  padding: 0 10px;
  display: flex;
  align-items: center;

  .type_arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 5rem;
    background-color: rgba(224, 228, 237, 0.3);
    justify-content: center;
    border-radius: 10px;
    padding: 0.1rem 0;

    img {
      height: 7px;
      width: 11px;
      margin-left: 4px;
    }
  }
}

.rs-table-hover .rs-table-row:hover .rs-table-cell,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background: #f9f8f8 !important;
}

.rs-table-hover .rs-table-row-header:hover .rs-table-cell,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group {
  background: $white !important;
}

.rs-table {
  border-bottom: $prl_table_border_width solid $prl_table_border_color;
}

.rs-table-header-row-wrapper {
  .rs-table-row {
    border-bottom: $prl_table_border_width solid $prl_table_border_color;
    border-left: $prl_table_border_width solid #fff;

    .rs-table-cell-first {
      border-left: $prl_table_hover_border_width solid transparent;
    }
  }

  .rs-table-cell-group-left-shadow,
  .rs-table-cell-group-shadow {
    box-shadow: none;
  }

  .rs-table-cell-group-left-shadow {
    box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
  }
}

.rs-table-body-row-wrapper {
  border-left: $prl_table_border_width solid $prl_table_border_color;
  border-right: $prl_table_border_width solid $prl_table_border_color;

  .rs-table-row {
    cursor: pointer;
    border-bottom: $prl_table_border_width solid $prl_table_border_color;
    filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0);
    -moz-filter: blur(0);
    -webkit-filter: blur(0);

    .rs-table-cell-first {
      border-left: $prl_table_hover_border_width solid transparent;
    }
  }

  .rs-table-row:hover {
    .rs-table-cell-first {
      border-left: $prl_table_hover_border_width solid
        $prl_table_hover_border_color;
    }
  }
}

.type_popover {
  width: 6.5rem;
  padding: 0;
  box-shadow: 0 1px 4px rgba(142, 142, 147, 0.3);
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #e0e4ed;
  font-weight: 400;
  text-transform: none;

  .arrow {
    display: none;
  }
  .rs-popover-content {
    form {
      display: flex;
      flex-direction: column;
      margin: 4px 0;

      label {
        margin: 0;
        padding: 0.25rem 0.75rem;
        cursor: pointer;
        font-size: 12px;
        color: #000;

        input {
          margin-right: 0.3rem;
          margin-top: -2px;
        }
      }

      .dataset {
        &:hover {
          background-color: rgba($prl_dataset_bgr, 0.8) !important;
        }
      }

      .curation {
        &:hover {
          background-color: rgba($prl_curation_bgr, 0.8) !important;
        }
      }
    }
  }
}

.rs-table-cell,
.rs-table-cell-group,
.rs-table-row {
  transition: none;
}

.rs-table:not(.rs-table-column-resizing)
  .rs-table-cell-header
  .rs-table-cell:hover
  ~ .rs-table-column-resize-spanner,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
  background-color: $prl_table_hover_border_color;
}

.rs-table-column-resize-spanner:before {
  border-color: transparent $prl_table_hover_border_color transparent
    transparent;
}

.rs-table-column-resize-spanner:after {
  border-color: transparent transparent transparent
    $prl_table_hover_border_color;
}

.rs-table-mouse-area {
  background: $prl_table_hover_border_color;
  top: 10px;
}

.rs-table-mouse-area > span {
  background-color: $prl_table_hover_border_color;
}

.rs-table-cell-header-icon-sort {
  color: $prl_table_hover_border_color;
}

.rs-table-cell-header-icon-sort-desc:after,
.rs-table-cell-header-icon-sort-asc:after {
  color: $prl_table_hover_border_color;
}

.list-datasets-templates,
.datasources {
  .edit-card-header,
  .delete-card {
    color: #80808070;
    cursor: pointer;
  }

  .edit-card-header {
    margin-left: 5px;

    &:hover {
      color: #808080c4;
    }
  }
}

.edit-dataset {
  .uk-modal-body {
    background: #ebf1f5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.rs-table-scrollbar-vertical {
  bottom: 0;
}

.rs-table-body-wheel-area,
.rs-table-header-row-wrapper,
.rs-table-scrollbar-vertical {
  top: 10px;
}
