@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./styles/fontawesome/all.css";
@import "~rsuite/dist/styles/rsuite-default.min.css";

/* lato-300 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url("./fonts/lato-v16-latin-ext_latin-300.woff2") format("woff2");
}
/* lato-300italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    url("./fonts/lato-v16-latin-ext_latin-300italic.woff2") format("woff2");
}
/* lato-italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"),
    url("./fonts/lato-v16-latin-ext_latin-italic.woff2") format("woff2");
}
/* lato-regular - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url("./fonts/lato-v16-latin-ext_latin-regular.woff2") format("woff2");
}
/* lato-700 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url("./fonts/lato-v16-latin-ext_latin-700.woff2") format("woff2");
}
/* lato-700italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url("./fonts/lato-v16-latin-ext_latin-700italic.woff2") format("woff2");
}

@font-face {
  font-family: rsuite-icon-font;
  src:
    url("~rsuite/dist/styles/fonts/rsuite-icon-font.ttf") format("truetype"),
    url("~rsuite/dist/styles/fonts/rsuite-icon-font.woff") format("woff");
}

button:focus {
  outline: 0!important;
}