@import "../../../../styles/variables";
@import "../../../../styles/dataset-report";

@mixin inputsStyle() {
  padding: 0.5rem;
  border-radius: 4px;
  @include _fonts_dis_keeps_select();
  height: $input-height;
}

.generate_data {
  height: 100%;

  .hu-right-container {
    display: flex;

    ._generate_synth-button {
      @include _button(7rem, $dataset_buttons-color);
    }
  }

  .generate_data_container {
    flex: 1;
    overflow: auto;

    .generate-columns {
      display: flex;
      height: 100%;
      padding: $padding 0;
      padding-left: calc(1.5rem - 5px);

      .sort_columns,
      .under_sort_columns {
        display: flex;
      }

      .sort_columns {
        z-index: auto;
        position: absolute;
        padding-bottom: $padding;
      }

      .under_sort_columns {
        height: 100%;

        .under_drag {
          width: 330px;
          margin: 0 5px;
        }
      }
    }

    .plus-button {
      width: 150px;
      height: 60px;
      margin: 25px;
      cursor: pointer;
      background-color: white;
      border-radius: 30px;
      display: -webkit-flex;
      display: flex;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
      transition: 0.3s;

      &:hover {
        box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.2);
      }

      .plus-button-style {
        text-align: center;
        color: #0283027d;
        display: flex;
        align-items: center;
        margin: 0 13px;
        margin-top: 3px;
      }

      .plus-button-items {
        display: flex;
        flex: 1;
        // flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        padding-left: 2px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.helper_class {
  background-color: red;
}

.active_drag,
.disabled_drag {
  width: 330px;
}

.disabled_drag {
  pointer-events: none;
}

.active_drag {
  cursor: move;
  box-shadow: 0 0px 5px red !important;
}

.generate-column {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 5px;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  z-index: auto;

  .rt-h-charts-item {
    display: flex;
    flex-direction: column;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom: $border-width solid $border-color;

    .rtable-header {
      text-align: center;
      display: flex;
      width: 100%;
      height: 44px;
      color: $black;
      position: relative;
      justify-content: space-between;
      align-items: center;
      padding: 0 $drp_padding;
      padding-bottom: 0.7rem;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .header-container {
        text-align: start;
        border: none;
        background-color: transparent;
        font-size: 16px;
        font-weight: 400;
        color: #1d2642;

        ._chart_header-text {
          padding-right: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .right-head-container {
        display: flex;
        align-items: center;

        .header-icon-style {
          display: flex;
          cursor: pointer;
          margin-left: 5px;

          &:hover {
            color: rgb(199, 33, 33);
          }
        }
      }

      .edit-header {
        position: absolute;
        right: 0;
        opacity: 0;
        padding: 0 2px;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover .edit-header {
        opacity: 1;
        transition: 0.1s;
      }
    }

    .move-header {
      background-color: lightblue;
      border-radius: 4px;
      opacity: 0.9;
    }
  }

  .statistics {
    min-height: 263px;
    padding: $drp_padding;
    padding-bottom: 0;
    line-height: 0.75rem;
    border-top: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: $drp_table_bgr-color;

    .separate-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 30px;
      margin-bottom: 5px;

      ._name {
        font-size: 12px;
        font-weight: bold;
        color: #1d2642;
        margin-right: 0.5rem;
      }

      .value {
        max-width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include _light_text_stat_rp();
      }

      .css-bg1rzq-control,
      .css-1szy77t-control {
        width: 170px;
        box-shadow: none;
      }

      .settings-label,
      .settings-value {
        display: flex;
        align-items: center;
      }

      .settings-label {
        flex: 1;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: $font-weight;
      }

      .settings-value {
        max-width: unset;
        width: 170px;

        &:focus {
          outline: none;
        }

        input:focus {
          outline: none;
        }

        .settings-inputs {
          width: 100%;
          border: $border-width solid $border-color;
          @include inputsStyle();

          &:focus {
            border-color: $blue_royal;
          }
        }

        .datepicker-style {
          width: 170px;
        }

        .input-error {
          border-color: $red;

          &:focus {
            border-color: $red;
          }
        }
      }

      .settings-dis-value {
        width: 170px;
        background-color: #e7e7e7a6;
        @include inputsStyle();
      }
    }
  }

  ._gen-cols-style {
    border-bottom: $border-width solid $border-color;
    border-radius: 0;

    .types-style {
      height: 100%;
      line-height: 0.75rem;

      .css-bg1rzq-control,
      .css-1szy77t-control {
        width: 170px;
        box-shadow: none;
        border: $border-width solid $border-color;
        background-color: $white;
        height: $input-height;
        min-height: $input-height;
        cursor: pointer;

        &:focus-within {
          border-color: $blue_royal;
        }
      }

      .css-1g6gooi {
        font-size: 12px;
        margin: 0;
      }

      .css-1ypjhyj-menu,
      .css-m6see5-menu {
        box-shadow: none;
        border: $border-width solid $border-color;
        margin-top: 5px;
      }

      .css-1wy0on6 {
        height: 30px;
        padding-right: 4px;
      }

      .css-16pqwjk-indicatorContainer,
      .css-1thkkgx-indicatorContainer {
        height: 32px;
        padding: 8px 0;
      }

      .css-4bsj01-indicatorContainer,
      .css-eclmz9-indicatorContainer {
        padding: 0;
        height: 14px;
        width: 14px;
      }

      .css-xo7z33-option {
        background-color: $blue_royal;
      }

      .css-17mggjs-singleValue,
      .css-frl60x-singleValue,
      .css-11unzgr {
        @include _fonts_dis_keeps_select();
        margin-top: 1px;
        margin-left: 0;
        margin-right: 0;
      }

      .css-11unzgr {
        margin-top: 0;
        div {
          padding: $input-padding;
        }
      }

      .css-bgvzuu-indicatorSeparator {
        display: none;
      }
    }
  }

  .list_column {
    display: flex;
    min-height: 1rem;

    ul {
      padding: 0;
      list-style: none;
      flex: 1;
      width: 100%;

      li {
        @include _table_row();
        border-bottom: $border-width solid $border-color;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.rs-picker-menu {
  width: 515px;
  margin-top: 5px;
  z-index: 130;
  box-shadow: none;
  border: $border-width solid $border-color !important;

  .rs-picker-daterange-header {
    font-size: 12px;
    border-bottom: $border-width solid $border-color !important;
  }

  .rs-picker-toolbar {
    border-top: $border-width solid $border-color !important;

    .rs-picker-toolbar-right-btn-ok {
      &:hover {
        background-color: $green_paris;
      }

      @include _button(3rem, $green_paris);
    }

    .rs-picker-toolbar-option {
      color: $blue_royal;
    }
  }

  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: $border-width solid $border-color !important;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-color: $blue_royal;
    background-color: $blue_royal;
  }

  .rs-calendar-header-backward,
  .rs-calendar-header-forward,
  .rs-calendar-header-title,
  .rs-calendar-table-cell-content {
    font-size: 13px;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: $border-width solid $blue_royal !important;
  }

  .rs-calendar-table-cell-content:hover {
    background-color: #deebff;
  }

  .rs-calendar-table-cell-in-range:before {
    background-color: #deebff;
  }
}

.rs-picker-toggle {
  width: 170px !important;
  border: $border-width solid $border-color !important;
  padding: 0.5rem !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  font-weight: 400 !important;
  height: $input-height !important;
  background-color: white;
  display: flex !important;
  align-items: center !important;

  &:focus-within {
    border-color: $blue_royal !important;
  }

  .rs-picker-toggle-caret {
    display: none;
  }

  .rs-picker-toggle-value {
    @include _fonts_dis_keeps_select();
    color: #000000 !important;
  }
}

.tooltip_generate {
  font-size: 10px;
  padding: 6px;
  border-radius: 3px;
  background-color: rgb(119, 119, 119);
  color: #000;
  position: absolute;
  z-index: 1070;
  display: block;

  .change-bars-settings-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    label {
      color: white;
    }

    input {
      width: 130px;
      border-radius: 1px;
      border: none;
      padding: 0 2px;
    }

    .buttons_container {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        width: 45px;
        margin: 0 5px;
        border-radius: 14px;
      }

      #delete_bar_button,
      #save_bar_button {
        transition: 0.15s;
        color: white;
      }

      #delete_bar_button {
        background-color: rgb(207, 0, 0);

        &:hover {
          background-color: rgb(233, 1, 1);
        }
      }

      #save_bar_button {
        background-color: rgba(3, 187, 3, 0.685);
        &:hover {
          background-color: rgb(3, 187, 3);
        }
      }
    }
  }
}
