@import "../styles/variables";
@import "../styles/dataset-report";

@mixin trash_button() {
  height: 27px;
  min-width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  border: 1px solid rgba(128, 128, 128, 0.363);
  cursor: pointer;
  background-color: #fafafa;

  &:hover {
    background-color: #f1f1f1;
  }
}

#list-datasets,
.edit-dataset {
  color: #666;

  .uk-modal-dialog.uk-margin-auto-vertical,
  .delete-project-styles,
  .uk-modal-header,
  .uk-modal-body,
  .uk-modal-footer {
    border-radius: 6px;
  }

  .uk-modal-dialog {
    width: 32rem;

    button.uk-modal-close-default.uk-close.uk-icon {
      right: 15px;
    }

    .uk-modal-header {
      padding: 10px 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: $border-width solid $border-color;

      .fa-file {
        font-size: 1.3rem;
        color: #5c7080;
      }

      .uk-modal-title {
        font-size: 1.3rem;
        font-weight: $font-weight;
        margin-left: 15px;
        color: $black;
      }
    }

    .uk-modal-body,
    .uk-modal-footer {
      padding: 20px;
      background: $drp_table_bgr-color;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .edit-formik {
        .input-container {
          margin-bottom: 10px;

          .uk-input {
            @include _input();
          }

          textarea {
            @include _input();
          }

          input.error,
          textarea.error {
            border-color: red;
          }

          label {
            @include _label();
            display: block;
            margin-bottom: 0;
          }

          .input-feedback {
            color: red;
            font-size: 11px;
          }
        }

        .invite-line {
          .complete-input {
            @include _input();
            width: 100%;
          }

          .uk-button {
            @include _button(3.4rem);
          }
        }
      }

      .inputs_select {
        line-height: 0.75rem;

        .error {
          .css-bg1rzq-control,
          .css-1szy77t-control {
            border-color: red;
          }
        }

        .css-bg1rzq-control,
        .css-1szy77t-control {
          width: 100%;
          box-shadow: none;
          border: $border-width solid $border-color;
          background-color: $white;
          height: $input-height;
          min-height: $input-height;
          cursor: pointer;

          &:focus-within {
            border-color: $blue_royal;
          }
        }

        .css-1g6gooi {
          font-size: 12px;
          font-weight: $font-weight;
          color: $black;
          margin: 0;
        }

        .css-kj6f9i-menu,
        .css-m6see5-menu {
          box-shadow: none;
          border: $border-width solid $border-color;
          margin-top: 5px;
        }

        .css-1wy0on6 {
          height: 30px;
          padding-right: 4px;
        }

        .css-16pqwjk-indicatorContainer,
        .css-1thkkgx-indicatorContainer {
          height: 32px;
          padding: 8px 0;
        }

        .css-4bsj01-indicatorContainer,
        .css-eclmz9-indicatorContainer {
          padding: 0;
          height: 14px;
          width: 14px;
        }

        .css-xo7z33-option {
          background-color: $blue_royal;
        }

        .css-dvua67-singleValue,
        .css-11unzgr,
        ._autocomplete-menu-styles {
          @include _fonts_dis_keeps_select();
          color: $black;
          font-weight: $font-weight;
          margin-left: 0;
          margin-right: 0;
        }

        .css-11unzgr,
        ._autocomplete-menu-styles {
          div {
            padding: $input-padding;
          }
        }

        .css-bgvzuu-indicatorSeparator {
          display: none;
        }
      }

      ._share-modal-styles {
        .css-bg1rzq-control,
        .css-1szy77t-control {
          width: 150px;
        }

        label {
          @include _label();
        }
      }

      .formik-submit {
        display: flex;
        justify-content: center;

        .uk-button {
          @include _button(8rem, $green_paris);
          width: auto;
        }

        ._edit_delete_datasource-button {
          @include _button(7rem, $blue_light_steel);
        }

        ._edit_check_datasource-button {
          @include _button(7rem, $blue_royal);
        }

        ._edit_save_datasource-button {
          @include _button(7rem, $green_paris);
        }
      }

      .email-div {
        flex: 1;
        height: $input-height;
        background-color: #f1f1f1;
        border-radius: $input-border-radius;
        display: flex;
        align-items: center;
        padding: $input-padding;
        @include _label();
      }
    }

    .uk-modal-footer {
      padding: 10px 20px;
      border-top: $border-width solid $border-color;
    }
  }

  .delete-project-styles {
    .uk-modal-body {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      color: $black;
    }

    .uk-modal-footer {
      ._delete_project_button_cancel {
        @include _button(7rem, $blue_light_steel);
      }

      ._delete_project_button {
        @include _button();
        margin-left: 1rem;
      }
    }
  }
}

.css-bg1rzq-control,
.css-1szy77t-control {
  width: 150px;
  height: 100%;
}

.set-queries-container,
.dashboards-header-buttons {
  .complete-input {
    @include _input();
    width: 100%;
  }

  .css-1pcexqc-container {
    width: 150px;
  }

  .css-bg1rzq-control,
  .css-1szy77t-control {
    width: 150px;
    box-shadow: none;
    border: $border-width solid $border-color;
    background-color: $white;
    height: $input-height;
    min-height: $input-height;
    cursor: pointer;

    &:hover {
      border: $border-width solid $border-color;
    }

    &:focus-within {
      border-color: $blue_royal;
    }
  }

  .css-1g6gooi {
    font-size: 12px;
    font-weight: $font-weight;
    color: $black;
    margin: 0;
  }

  .css-kj6f9i-menu,
  .css-m6see5-menu {
    box-shadow: none;
    border: $border-width solid $border-color;
    margin-top: 5px;
    line-height: 0.75rem;
  }

  .css-1wy0on6 {
    height: 30px;
    padding-right: 4px;
  }

  .css-16pqwjk-indicatorContainer,
  .css-1thkkgx-indicatorContainer {
    height: 32px;
    padding: 8px 0;
  }

  .css-4bsj01-indicatorContainer,
  .css-eclmz9-indicatorContainer {
    padding: 0;
    height: 14px;
    width: 14px;
  }

  .css-xo7z33-option {
    background-color: $blue_royal;
  }

  .css-dvua67-singleValue,
  .css-11unzgr,
  ._autocomplete-menu-styles {
    @include _fonts_dis_keeps_select();
    color: $black;
    font-weight: $font-weight;
    margin-left: 0;
    margin-right: 0;
  }

  .css-11unzgr,
  ._autocomplete-menu-styles {
    line-height: 0.75rem;
    div {
      padding: $input-padding;
    }
  }

  .css-bgvzuu-indicatorSeparator {
    display: none;
  }
}

.dashboards-header-buttons {
  .css-1pcexqc-container,
  .css-bg1rzq-control,
  .css-1szy77t-control {
    width: 7.8rem;
  }
}
