// Main Colours

$black: #000000;
$white: #ffffff;
$red: #ff0000;
$blue_yankees: #1d2642;
$blue_royal: #515fe7;
$pink_shocking: #ff15c2;

// Additional Colours

$platinum: #e0e4ed;
$blue_light_steel: #5c606f; // #73788a; // #989eb5; //#bcc4db; // for light buttons
$green_paris: #45cb85;
$yellow_mikado: #ffbe0b;
$honeydew: #e3f7ed;
$cornsilk: #fff5da;

// Dataset Button Color
$dataset_buttons-color: #ff2366;

// Projects type column for dataset
$prl_dataset_bgr: $honeydew; // background color
$prl_dataset_text: $green_paris; // text color

// Projects type column for curation
$prl_curation_bgr: $cornsilk; // background color
$prl_curation_text: $yellow_mikado; // text color

// MAIN STYLES

$font-weight: 700;
$font-size: 0.875rem;
$border-color: $platinum; // input, form, panel, header bottom
$border-width: 1px;
$light_label_color: #777c8d; // $blue_light_steel;
$padding: 1.5rem; // general padding in all pages

$border-radius: 10px; // for upload, datasources, source

// HEADER

$header-height: 4.5rem;

// SIDEBAR

$sb_width: 9rem;
$sb_font-size: 0.8rem;
$sb_font-weight: 500;

// UPLOAD, DROP ZONE STYLES

$drz_border-style: dashed;
$drz_border-width: 2px;
$drz_border-color: $border-color;
$drz_border-radius: $border-radius;

// INPUT STYLE

$input-border-radius: 4px;
$input-height: 2rem;
$input-font-size: 0.8rem;
$input-border-width: $border-width;
$input-border-color: $border-color;
$input-border-focus: $blue_royal;
$input-padding: 0.5rem;

@mixin _input() {
  height: $input-height;
  font-weight: $font-weight;
  font-size: $input-font-size;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;
  color: $black;
  padding: $input-padding;

  &:focus {
    border-color: $input-border-focus;
    outline: none;
    box-shadow: none;
  }
}

// LABEL STYLE (as Profile)

@mixin _label() {
  font-size: $input-font-size;
  color: $light_label_color;
  font-weight: 400;
}

// BUTTON STYLE

$button-height: 2rem !default; // $input-height
$button-width: 7rem !default;
$button-border-radius: 4px;
$button-font-weight: $font-weight;
// $button-font-size: $font-size;
$button-font-size: 0.75rem;
$button-line-height: 2rem;

@mixin _button($width: $button-width, $bg_color: $blue_royal, $height: $button-height) {
  $shadow: rgba($bg_color, 0.5);

  min-width: $width;
  width: $width;
  height: $height;
  padding: 0 15px;
  text-align: center;
  cursor: pointer;
  background-color: $bg_color;
  color: $white;
  border-radius: $button-border-radius;
  border: $border-width solid transparent;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  line-height: $height - 0.1rem;
  box-shadow: 0 2px 9px $shadow;
  text-transform: none;

  &:hover {
    box-shadow: 0 2px 13px $shadow;
  }
}

:export {
  // for upload page, drop zone(Source)

  border_color: $drz_border-color;
  border_style: $drz_border-style;
  border_width: $drz_border-width;
  border_radius: $drz_border-radius;
  border_active: $green_paris;
  border_reject: $red;

  // General styles

  bgr_color: $platinum;
  switch_border_color: $blue_royal; // for table container bottom border color
  yellow: $cornsilk;
  orange: $yellow_mikado;
  green: $green_paris;
  blue_royal: $blue_royal;
  input-height: $input_height;
  b_width: $border-width;
  b_color: $border-color;
  input_b_radius: $input-border-radius;
  padding: $padding;

  // Projects type filter backgrounds

  dataset_bgr: $prl_dataset_bgr;
  curation_bgr: $prl_curation_bgr;
  dataset_color: $prl_dataset_text;
  curation_color: $prl_curation_text;
}
