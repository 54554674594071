@import '../../styles/variables';
@import '../../styles/dataset-report';

@mixin trash_button() {
  height: 27px;
  min-width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  border: 1px solid rgba(128, 128, 128, 0.363);
  cursor: pointer;
  background-color: #fafafa;

  &:hover {
    background-color: #f1f1f1;
  }
}

$dst_border-radius: $border-radius; // for chart block
$dst_padding: $drp_padding; // padding for chart block
$dst_table_bgr-color: $drp_table_bgr-color; // background table blocks
$dst_border-width: $border-width; // for table borders

.table {
  height: 100%;
  overflow: hidden;

  .t-container {
    overflow-y: auto;
  }

  .t-container,
  .rt-table,
  .rt-tbody {
    height: 100%;
  }

  .t-container {
    padding: 0;

    .rt-h-additional-container {
      border-bottom: $dst_border-width solid $border-color;
      flex-direction: column;
      position: sticky;
      left: 0;

      .rt-h-switcher-container {
        .rt-h-switcher {
          margin-top: 1.2rem;

          .switcher {
            margin: 0;
            display: flex;

            .sw-with-legend {
              display: flex;
              margin-left: 15px;
              width: 9rem;
              position: relative;

              .switch-button {
                width: 100%;
                cursor: pointer;
                padding: 0 15px;
                line-height: 30px;
                text-transform: none;
                background-color: transparent;
                border: $dst_border-width solid $border-color;
                border-bottom: 0;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                text-align: center;
                padding-left: 30px;
                font-size: 16px;
                font-weight: 400;
                color: #1d2642;
              }

              .switch-legend {
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: red;
                align-self: center;
                left: 25px;
                border-radius: 10px;
              }

              .active {
                border-bottom: 1.2px solid $blue_royal;
              }
            }
          }
        }
      }

      .header-down {
        padding: 0.6rem $dst_padding;
        background-color: $dst_table_bgr-color;
        border-right: $dst_border-width solid $border-color;
        border-left: $dst_border-width solid $border-color;
        display: flex;

        .hd-left-container,
        .hd-right-container {
          display: flex;
        }

        .hd-left-container {
          .hd-l-info {
            margin-right: 15px;

            .num-margin {
              margin-right: 5px;
            }
          }

          ._f_text,
          ._s_text {
            position: absolute;
            top: 0;
          }

          ._f_text {
            left: 0;
          }

          ._s_text {
            left: 190px;
          }
        }

        .hd-right-container {
          .hd-r-buttons {
            @include trash_button();
          }

          .l-button {
            background-color: #d9e1e6;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          .r-button {
            background-color: #fafafa;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.table,
.generate_data {
  .rt-header-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    .rt-h-charts {
      display: flex;
      flex: 1 0 auto;

      .rt-h-charts-item {
        width: 300px;
        display: flex;
        flex-direction: column;
        border-radius: $dst_border-radius;
        border: $dst_border-width solid $border-color;
        border-right: ($dst_border-width/2) solid $border-color;
        border-left: ($dst_border-width/2) solid $border-color;

        .rtable-header {
          text-align: center;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 44px;
          color: $black;
          align-items: center;
          padding: 0 $dst_padding;
          padding-bottom: 0.7rem;

          .header-container {
            width: 100%;
            text-align: start;
            border: none;
            background-color: transparent;
            font-size: 16px;
            font-weight: 400;
            color: #1d2642;
            position: relative;
            display: flex;

            ._chart_header-text {
              padding-right: 0.2rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .edit-header {
              opacity: 0;
              margin: 0;

              img {
                height: 1.7rem;
                width: 1.7rem;
                max-width: unset;
              }

              &:hover {
                cursor: pointer;
              }
            }

            ._bind-icon {
              position: absolute;
              right: 0;
              top: 0;

              img {
                height: 0.9rem;
                width: 0.9rem;
                max-width: unset;
              }
            }
          }

          .complex_label_container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            font-size: 12px;
            font-weight: $font-weight;

            .complex_label_style {
              background-color: $yellow_mikado;
              padding: 0 0.3rem;
              border-radius: 10px;
            }
          }

          &:hover .edit-header {
            opacity: 1;
            transition: 0.1s;
          }
        }

        .rtable-charts {
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .statistics-container {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    border-left: $dst_border-width solid $border-color;

    .statistics {
      width: 300px;
      padding: $dst_padding;
      padding-bottom: 0.2rem;
      line-height: 0.75rem;
      border-right: $dst_border-width solid $border-color;
      background-color: $dst_table_bgr-color;

      .separate-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;

        ._name {
          font-size: 12px;
          font-weight: bold;
          color: #1d2642;
          margin-right: 0.5rem;
        }

        .value {
          max-width: 190px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @include _light_text_stat_rp();
        }
      }
    }
  }

  .ReactTable {
    border: $dst_border-width solid $border-color;

    .rt-table {
      overflow-y: auto;
      overflow-x: hidden;

      .rt-tbody {
        overflow: overlay;

        .rt-tr-group {
          border-bottom: $dst_border-width solid $border-color;

          .rt-tr.-odd {
            background-color: transparent;
          }

          .rt-tr:hover {
            background: rgba(0, 0, 0, 0.05);
          }

          .rt-td {
            @include _table_row();
            border-right: $dst_border-width solid $border-color;

            div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .rt-noData {
    top: 70%;
  }
}

.rt-header-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .rt-h-charts {
    display: flex;
    flex: 1 0 auto;

    .rt-h-charts-item {
      width: 300px;
      display: flex;
      flex-direction: column;
      border-radius: $dst_border-radius;
      border: $dst_border-width solid $border-color;
      border-right: ($dst_border-width/2) solid $border-color;
      border-left: ($dst_border-width/2) solid $border-color;

      .rtable-header {
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 44px;
        color: $black;
        align-items: center;
        padding: 0 $dst_padding;
        padding-bottom: 0.7rem;

        .header-container {
          width: 100%;
          text-align: start;
          border: none;
          background-color: transparent;
          font-size: 16px;
          font-weight: 400;
          color: #1d2642;
          position: relative;
          display: flex;

          ._chart_header-text {
            padding-right: 0.2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .edit-header {
            opacity: 0;
            margin: 0;

            img {
              height: 1.7rem;
              width: 1.7rem;
              max-width: unset;
            }

            &:hover {
              cursor: pointer;
            }
          }

          ._bind-icon {
            position: absolute;
            right: 0;
            top: 0;

            img {
              height: 0.9rem;
              width: 0.9rem;
              max-width: unset;
            }
          }
        }

        .complex_label_container {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 12px;
          font-weight: $font-weight;

          .complex_label_style {
            background-color: $yellow_mikado;
            padding: 0 0.3rem;
            border-radius: 10px;
          }
        }

        &:hover .edit-header {
          opacity: 1;
          transition: 0.1s;
        }
      }

      .rtable-charts {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rtable-types {
        padding: 0.3rem $dst_padding;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        border-bottom-left-radius: $dst_border-radius;
        border-bottom-right-radius: $dst_border-radius;
        border-top: $dst_border-width solid $border-color;
        background-color: $dst_table_bgr-color;

        .types {
          min-height: 29px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .checkbox {
            margin: 0;
            display: flex;
            @include _fonts_dis_keeps_select();

            ._types-dis-keeps {
              @include _checkbox();
            }
          }

          ._undo-button {
            @include _button(3rem);
            padding: 0;
          }
        }
      }
    }
  }
}

.statistics-container {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  border-left: $dst_border-width solid $border-color;

  .statistics {
    width: 300px;
    padding: $dst_padding;
    padding-bottom: 0.2rem;
    line-height: 0.75rem;
    border-right: $dst_border-width solid $border-color;
    background-color: $dst_table_bgr-color;

    .separate-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;

      ._name {
        font-size: 12px;
        font-weight: bold;
        color: #1d2642;
        margin-right: 0.5rem;
      }

      .value {
        max-width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include _light_text_stat_rp();
      }
    }
  }
}

.ReactTable {
  border: $dst_border-width solid $border-color;

  .rt-table {
    overflow-y: auto;
    overflow-x: hidden;

    .rt-tbody {
      overflow: overlay;

      .rt-tr-group {
        border-bottom: $dst_border-width solid $border-color;

        .rt-tr.-odd {
          background-color: transparent;
        }

        .rt-tr:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        .rt-td {
          @include _table_row();
          border-right: $dst_border-width solid $border-color;

          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.rt-noData {
  top: 70%;
}

.types-style {
  height: 100%;
  line-height: 0.75rem;
  display: flex;

  .css-bg1rzq-control,
  .css-1szy77t-control {
    width: 110px;
    box-shadow: none;
    border: $dst_border-width solid $border-color;
    background-color: $white;
    height: $input-height;
    min-height: $input-height;
    cursor: pointer;

    &:focus-within {
      border-color: $blue_royal;
    }
  }

  .css-1g6gooi {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
  }

  .css-asyc5z-menu {
    box-shadow: none;
    border: $dst_border-width solid $border-color;
    margin-top: 5px;
  }

  .css-1wy0on6 {
    height: 30px;
    padding-right: 4px;
  }

  .css-16pqwjk-indicatorContainer,
  .css-1thkkgx-indicatorContainer {
    height: 32px;
    padding: 8px 0;
  }

  .css-4bsj01-indicatorContainer,
  .css-eclmz9-indicatorContainer {
    padding: 0;
    height: 14px;
    width: 14px;
  }

  .css-17mggjs-singleValue,
  .css-11unzgr {
    @include _fonts_dis_keeps_select();
    margin-top: 1px;
    top: 47%;
  }

  .css-bgvzuu-indicatorSeparator {
    display: none;
  }

  .search-columns {
    .h-model-input {
      display: flex;
      align-items: center;
      position: relative;

      .m-input {
        width: 9rem;
        height: 21px;
        text-align: start;
        border-radius: 21px;
        padding-right: 32px;
      }

      .switch_ni {
        position: absolute;
        right: 0;
        height: 19px;
        margin-right: 0.1px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        .switch_ni-button {
          margin-right: 7px;
          margin-left: 3px;
        }
      }

      .error {
        border-color: red;
      }
    }

    .css-bg1rzq-control,
    .css-1szy77t-control {
      width: 14rem;
    }
  }
}
