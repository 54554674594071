.domain {
  stroke: rgba(0, 0, 0, 0.05);
}
.tick {
  color: rgba(0, 0, 0, 0.3);
}

.tooltip {
  font-size: 10px;
  padding: 6px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 47%, 0.6);
  color: #fff;
  pointer-events: none;

  .t-ul-style {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    .t-li-style {
      display: flex;
      align-items: center;

      .t-li-legend {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
