@import "../../../styles/variables";

// CONSTANTS

$table_font-size: 0.8rem; // font-size switch buttons

.drop_container {
  .smooth-dnd-container {
    display: flex !important;
    min-height: 300px;

    .smooth-dnd-ghost {
      width: 330px !important;
    }
  }

  .to_start {
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    font-weight: $font-weight;
    font-size: 0.8rem;
    color: $light_label_color;
  }
}

.smooth-dnd-drop-preview-constant-class {
  width: 310px !important;
}

.smooth-dnd-draggable-wrapper {
  overflow: unset !important;
}

.vertical {
  display: flex !important;
  flex-direction: column;
  height: calc(100vh - 490px);
  width: 100%;
  overflow-y: auto;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  width: 430px !important;
  margin: 5px;
  height: 290px;
  border-radius: $border-radius;
}

.opacity-ghost {
  transition: all 0.18s ease;
  cursor: ns-resize;
  border-radius: 10px;
  /* transform: rotateZ(5deg); */
  background-color: $white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.tables_container {
  ._switch-buttons {
    border: $border-width solid $border-color;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $white;
    line-height: 2rem;
    text-transform: none;
    height: 2rem;
    font-weight: $font-weight;
    font-size: $table_font-size;
    color: $light_label_color;
    min-width: 6rem;
    padding: 0;
    padding-bottom: 2px;
    box-sizing: content-box;
  }

  .uk-active {
    border-color: $blue_royal;
    color: $black;
  }
}

.tables_plus_container {
  cursor: pointer;
  position: absolute;
  z-index: 120;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;

  .source_plus_icon {
    font-size: 1.2em;
    background-color: #f2f9ff;
    border-radius: 1px;
    padding: 1px;
    width: 100%;
    height: 100%;

    &::before {
      color: #72f778;
    }
  }
}

.tables_columns {
  min-width: 180px;
  border-radius: 10px;
  text-align: start;
  padding-bottom: 0.5rem;
  border: $border-width solid $border-color;

  ._cell-styles {
    white-space: nowrap;
    border-bottom: $border-width solid $border-color;
    padding: 0.3rem 0.5rem;

    ._source-light-text {
      font-size: 14px;
      font-weight: 400;
      color: $light_label_color;
    }
    ._source-bold-text {
      font-size: 14px;
      font-weight: bold;
      color: $black;
    }
  }

  ._add-button {
    @include _button(5rem, $green_paris, 1.5rem);
    margin: 0.5rem 0.5rem 0 0.5rem;
    background: $white;
    border-color: $green_paris;
    color: $black;
  }
}

.source-drop-style {
  .statistics {
    min-height: 113px;
    padding-top: 0.2rem;

    .separate-row {
      min-height: unset;
    }
  }
}

.uk-description-list-divider > dt:nth-child(n + 2) {
  border-top-style: dashed !important;
}

.table_text_style {
  text-transform: none !important;
  padding-top: 0;
  border-top: none;
  display: flex;
  font-weight: $font-weight;
  font-size: $font-size;
  margin: 9px 0;
}

.switch-container {
  margin-top: 5px !important;
  margin-bottom: 0;
}
