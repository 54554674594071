@import "../styles/variables";

@mixin trash_button() {
  height: 27px;
  min-width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  border: 1px solid rgba(128, 128, 128, 0.363);
  cursor: pointer;
  background-color: #fafafa;

  &:hover {
    background-color: #f1f1f1;
  }
}

$hd_button-width: 7rem;

.types-style {
  .css-bg1rzq-control,
  .css-1szy77t-control {
    width: $hd_button-width;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .header_bind-icon {
    width: $input-height;
    height: $input-height;
    border: $border-width solid $border-color;
    border-radius: $input-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .open_complex_select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: transparent;
  }
}

.header-complex-row {
  position: relative;
  padding: 0 $padding 0.7rem;
  min-height: 42px;

  .types-style {
    flex-direction: column;
    margin-left: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;

    .css-bg1rzq-control,
    .css-1szy77t-control {
      width: 180px;
    }

    label {
      margin-bottom: 0.2rem;
      font-size: 12px;
      font-weight: 700;
      margin-left: 11px;

      .label_star {
        position: relative;
        display: inline-block;

        .required_star {
          position: absolute;
          bottom: -1px;
          right: -8px;
          color: $red;
          font-size: 1rem;
        }
      }
    }

    .error {
      .css-bg1rzq-control,
      .css-1szy77t-control {
        border-color: red;
      }
    }
  }

  .complex-buttons {
    align-self: flex-end;
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.7rem;

    .complex_delete-button,
    .complex_cancel-button,
    .complex_bind_save-button {
      margin-left: 1rem;
    }

    .complex_delete-button {
      @include _button($hd_button-width, $blue_light_steel);
    }

    .complex_cancel-button {
      @include _button($hd_button-width);
    }

    .complex_bind_save-button {
      @include _button($hd_button-width, $green_paris);
    }
  }
}

.header {
  display: block;

  .header-up,
  .header-complex-row {
    position: relative;
    padding: 0.7rem $padding;
    min-height: 42px;
  }

  .header-complex-row {
    padding-top: 0;

    .types-style {
      flex-direction: column;
      margin-left: 1rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;

      .css-bg1rzq-control,
      .css-1szy77t-control {
        width: 180px;
      }

      label {
        margin-bottom: 0.2rem;
        font-size: 12px;
        font-weight: 700;
        margin-left: 11px;

        .label_star {
          position: relative;
          display: inline-block;

          .required_star {
            position: absolute;
            bottom: -1px;
            right: -8px;
            color: $red;
            font-size: 1rem;
          }
        }
      }

      .error {
        .css-bg1rzq-control,
        .css-1szy77t-control {
          border-color: red;
        }
      }
    }

    .complex-buttons {
      align-self: flex-end;
      flex-wrap: nowrap;
      display: flex;
      margin-bottom: 0.2rem;

      .complex_delete-button,
      .complex_cancel-button,
      .complex_bind_save-button {
        margin-left: 1rem;
      }

      .complex_delete-button {
        @include _button($hd_button-width, $blue_light_steel);
      }

      .complex_cancel-button {
        @include _button($hd_button-width);
      }

      .complex_bind_save-button {
        @include _button($hd_button-width, $green_paris);
      }
    }
  }

  .header-up {
    .hu-left-container,
    .hu-right-container {
      .add_cursor {
        cursor: pointer;

        &:hover {
          opacity: 0.85;
        }
      }

      .manage_icons {
        margin-right: 10px;
        background-color: #ff2366;

        .m_btn {
          text-align: center;
          color: white;
        }
      }
    }

    .hu-left-container {
      // width: 18rem;
      display: flex;
      align-items: center;

      .types-style {
        margin-right: 1.5rem;

        .css-bg1rzq-control,
        .css-1szy77t-control {
          width: $hd_button-width;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .header_bind-icon {
          width: $input-height;
          height: $input-height;
          border: $border-width solid $border-color;
          border-radius: $input-border-radius;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            height: 1.5rem;
            width: 1.5rem;
          }
        }

        .open_complex_select {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-color: transparent;
        }
      }

      ._prev_learn-button {
        cursor: pointer;
        border-radius: 20px;
        margin-right: 1.5rem;

        ._learn-button {
          @include _button($hd_button-width, $dataset_buttons-color);
        }
      }

      ._stop-button {
        @include _button($hd_button-width, $blue_light_steel);
        margin-right: 1.5rem;
      }

      .synth-status {
        display: flex;
        margin-right: 1.5rem;

        .status_progress_container {
          vertical-align: text-bottom;
          margin-right: 2px;
          display: flex;
          align-items: center;
          width: 100%;
          height: 21px;
          position: relative;
        }

        .progress_text {
          width: 190px;
          position: absolute;
          z-index: 111;
          flex-wrap: nowrap;
          height: 21px;
          padding-left: 8px;
        }

        .status-icons {
          vertical-align: inherit;
          min-width: 20px;
        }
      }

      .hu-tabs-label {
        margin-right: 15px;
      }

      .hu-l-modal {
        display: flex;
        align-items: center;

        .up-style {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.1rem;
          position: relative;
        }

        .down-style {
          color: #0404ffad;
        }
      }
    }

    .hu-right-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      ._prev_synth-button {
        margin: 0 1.5rem;
        cursor: pointer;
        border-radius: 20px;
        display: flex;

        ._synthesize-button {
          @include _button($hd_button-width, $dataset_buttons-color);
        }

        .progress-synthetic {
          width: 7.5rem;
          margin-left: 10px;
          display: flex;
          align-items: center;

          .uk-progress {
            height: 21px;
          }

          .progress_text_synthetic {
            width: 7.5rem;
            position: absolute;
            z-index: 111;
            flex-wrap: nowrap;
            height: 21px;
            padding-left: 8px;
          }
        }
      }

      .h-model-input {
        display: flex;
        align-items: center;

        .m-input {
          width: 5.5rem;
          height: 21px;
          text-align: end;
        }

        .error {
          border-color: red;
        }
      }

      .rows-word {
        margin-left: 0.4rem;
      }

      .synth-status {
        display: flex;

        .status-icons {
          vertical-align: inherit;
          min-width: 20px;
        }
      }

      .model-button {
        margin-left: 10px;
      }

      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .uk-input,
      .uk-select,
      .uk-textarea {
        border-radius: 21px;
      }

      .uk-input {
        height: 32px;
      }

      .uk-link:hover,
      a:hover {
        color: transparent;
        text-decoration: none;
      }

      .link-style {
        margin-right: 1rem;
      }

      .left-button {
        display: flex;
        align-items: center;
        background-color: #4d90dceb;
        color: white;
        border-radius: 3px;
        min-height: 27px;
        cursor: pointer;
      }

      .right-button {
        @include trash_button();
        border-radius: 4px;
      }

      .header-logout-btn {
        cursor: pointer;
        color: #666;

        &:hover {
          color: rgba(128, 128, 128, 0.92);
        }
      }
    }

    ._download-button {
      @include _button($hd_button-width);
      margin-left: 0.5rem;
    }
  }

  .header-down {
    padding: 5px 0;
    background-color: #f4f8fb;
    display: flex;

    .hd-left-container,
    .hd-right-container {
      display: flex;
    }

    .hd-left-container {
      .hd-l-info {
        margin-right: 15px;

        .num-margin {
          margin-right: 5px;
        }
      }
    }

    .hd-right-container {
      .hd-r-buttons {
        @include trash_button();
      }

      .l-button {
        background-color: #d9e1e6;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .r-button {
        background-color: #fafafa;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.header,
.analyze,
.generate_data,
.upload,
.source,
.rp_source {
  .overlay-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    right: 60%;
    left: 40%;
    width: 20%;
    background-color: #fff;
    opacity: 0.9;
    z-index: 10;
  }
}

.header,
.analyze {
  .tabs-header {
    ._project-tabs {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;

      .uk-tab {
        margin-left: 0;
        margin-bottom: 0;
        padding-left: $padding;
        width: 100%;

        &:before {
          left: 0;
          border-bottom: $border-width solid $border-color;
        }

        li {
          padding: 0;
          width: 7rem;
          font-weight: $font-weight;
          font-size: $font-size;

          a {
            text-transform: none;
          }

          .tab-text {
            color: $light_label_color;
          }
        }
      }

      .uk-tab > .uk-active > a {
        color: $black;
        border-bottom: 1.2px solid $blue_royal;
      }
    }

    .hu-l-modal {
      display: flex;
      align-items: center;
      position: absolute;
      left: 17px;
      top: 9px;
      font-size: 16px;

      .up-style {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.1rem;
        position: relative;
      }

      .down-style {
        color: #0404ffad;
      }
    }
  }
}

.bind_popover_class {
  .arrow {
    margin-left: -36px !important;
  }
}
